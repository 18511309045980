<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Tipo de Cambio - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                  
                  <b-col md="2">
                    <b-form-group label="Fecha:">
                      <b-form-input class="text-center" type="date" v-model="exchange_rate.date"></b-form-input>
                      <small v-if="errors.date" class="form-text text-danger">Ingrese una fecha</small>
                    </b-form-group>
                  </b-col>
          
                  <b-col md="3">
                    <b-form-group label="USD :">
                      <b-form-input class="text-right" type="number" step="any" v-model="exchange_rate.usd"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="CLP :">
                      <b-form-input class="text-right" type="number" step="any" v-model="exchange_rate.usd"></b-form-input>
                    </b-form-group>
                  </b-col>


                  <b-col md="3">
                    <b-form-group label="Estado :">
                      <b-form-select v-model="exchange_rate.state" :options="state"></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col md="5"></b-col>
                  <b-col md="2">
                    <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                  </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

     <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
var moment = require("moment");
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ExchangeRate',
      role:2,
      exchange_rate: {
        id_exchange_rate: "",
        id_user: "",
        date: moment(new Date()).local().format("YYYY-MM-DD"),
        usd: (0).toFixed(2),
        clp: (0).toFixed(2),
        state: 1,
      },
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      errors: {
        date: false,
        usd:false,
        clp:false,
      },
      validate: false,
    };
  },
  mounted() {
  
  },
  methods: {
    AddExchangeRate,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};



function AddExchangeRate() {
  let me = this;
  let url = me.url_base + "category-product/add";
  let data = new FormData();
  data.append("name", me.exchange_rate.name);
  data.append("description", me.exchange_rate.description);
  data.append("photo", me.exchange_rate.photo);
  data.append("classification", me.exchange_rate.classification);
  data.append("state", me.exchange_rate.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 2},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.exchange_rate.name = "";
        me.exchange_rate.description = ""
        me.exchange_rate.state = 1;
        me.exchange_rate.photo = 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg';
        me.photo = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {
  this.errors.name = this.exchange_rate.name.length == 0 ? true : false;
  this.errors.state = this.exchange_rate.state.length == 0 ? true : false;
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

   if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar la categoria ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddExchangeRate(me);
      }
    });

  }
}
</script>
